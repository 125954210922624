.main-bg {
  background: radial-gradient(100% 197.75% at 0% 0%, #228848 0%, #8edd65 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-bg h1 {
  color: #fff;
  margin: 25px 0 0 0;
  padding: 0;
}
.main-bg p {
  color: #fff;
  margin: 5px 0 20px 0;
  padding: 0;
}
.main-bg a{
    border: 1px solid #333;
    border-radius: 24px;
    color: #333;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    width: 150px;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    display: block;
}
