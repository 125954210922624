.form {
  margin-top: 40px;
  width: 610px !important;
  min-height: 485px !important;
  overflow: auto;
  border-radius: 24px !important;
  background-color: white !important;
  position: relative;
}
.items-div {
  display: inline-flex !important;
  align-items: center;
}
.forget-password {
  color: #43b02a !important;
  font-size: 16px !important;
  line-height: 1.5px !important;
  letter-spacing: 0.23px !important;
  font-family: 'SF-Pro-Regular' !important;
  cursor: pointer;
  font-style: normal;
  text-decoration: underline;
  font-weight: normal !important;
  display: inline-flex !important;
}
.remember-me {
  font-size: 14px !important;
  font-family: 'SF-Pro-Regular' !important;
  font-weight: normal !important;
  line-height: 1.71px !important;
  letter-spacing: 0.23px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  margin-left: 10px !important;
  display: inline-flex !important;
}

.makeStyles-rootInput-2 .MuiInput-root.Mui-error .MuiInput-input {
  color: #000000 !important;
}
