/** Default Styling **/

/*****Fonts*****/

@font-face {
  font-family: 'SF-Pro-Regular';
  src: url('assests/fonts/SFPRODISPLAYREGULAR.ttf') format('truetype');
}
@font-face {
  font-family: 'SF-Pro-Medium';
  src: url('assests/fonts/SFPRODISPLAYMEDIUM.ttf') format('truetype');
}
@font-face {
  font-family: 'SF-Pro-Bold';
  src: url('assests/fonts/SFPRODISPLAYBOLD.ttf') format('truetype');
}
body,
html {
  padding: 0;
  margin: 0;
  height: 100vh;
}
* {
  font-family: 'SF-Pro-Regular', sans-serif !important;
}
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e8e93;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#root {
  padding: 0;
  margin: 0;
  min-width: 1024px;
  height: 100%;
}
button {
  font-weight: normal !important;
}

.auth-bg {
  background: radial-gradient(100% 197.75% at 0% 0%, #228848 0%, #8edd65 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  /* position: fixed; */
}

.auth-form-class {
  width: 80% !important;
  padding-left: 10% !important;
}

.auth-header-title {
  padding-left: 10% !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Bold' !important;
  line-height: 1.11px !important;
  letter-spacing: 0.23px !important;
  margin-top: 60px !important;
  margin-bottom: 50px !important;
}

.auth-label {
  font-family: 'SF-Pro-Bold' !important;
  /* font-style: Semibold !important; */
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #1e272e !important;
  margin-bottom: 10px !important;
}

.auth-error {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  /* font-style: Regular !important; */
  line-height: 16px !important;
  letter-spacing: 0.23px !important;
  color: #ff3b30 !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  font-family: 'SF-Pro-Regular' !important;
}

.auth-button {
  background-color: #d1d1d6 !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  height: 48px !important;
  letter-spacing: 0.23px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  box-shadow: none !important;
  margin-bottom: 60px !important;
}

.auth-button-show {
  background-color: #43b02a !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.23px !important;
  height: 48px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  box-shadow: none !important;
}

.auth-inputField {
  font-family: 'SF-Pro-Regular' !important;
  font-size: 16px !important;
  font-weight: normal;
  font-style: normal !important;
  line-height: 1.5px !important;
  letter-spacing: 0.2px !important;
  color: #1e272e !important;
  margin-top: 0px !important;
  border: none !important;
}

.auth-inputField::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.auth-inputField::after {
  border-bottom: 2px solid #8e8e93 !important;
}

.custom-input .MuiSelect-select {
  width: 100% !important;
  padding: 5px 0px !important;
  margin-top: 8px !important;
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
  mix-blend-mode: normal !important;
}
.custom-input .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
  border-bottom: 1px solid #1e272e !important;
  border-radius: 0 !important;
}
.custom-input .MuiInputLabel-root {
  font-style: normal !important;
  color: #8e8e93 !important;
  mix-blend-mode: normal;
  top: -3px !important;
  left: -15px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.2px !important;
  color: rgb(30, 39, 46) !important;
  opacity: 0.5 !important;
}
.custom-input .MuiInputLabel-root.Mui-focused {
  opacity: 0 !important;
}
.Toastify__progress-bar {
  opacity: 0 !important;
}
.Toastify__toast-icon {
  margin-left: 10px;
  padding-right: 0px;
  margin-right: 0px;
  margin-inline-end: 0px !important;
}
.Toastify__toast-container--top-center {
  top: 50px !important;
  left: 50% !important;
}
.Toastify__toast {
  display: flex;
  text-align: center;
  box-shadow: none !important;
  padding: 0 20px !important;
  height: 30px !important;
  min-height: 50px !important;
  flex-direction: row-reverse !important;
}
.Toastify__close-button {
  display: none !important;
}
.Toastify__toast-container {
  width: auto !important;
  padding: 0 20px !important;
}
.Toastify__toast--success {
  background: #d9efd4 !important;
  border-radius: 12px !important;
}
.Toastify__toast-body {
  flex-direction: row-reverse !important;
}
.Toastify__toast--success .Toastify__toast-body div {
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal;
  font-weight: 590;
  font-size: 15px;
  line-height: 18px;
  color: #43b02a;
  letter-spacing: 0.6px;
}
.Toastify__toast--error {
  background: #efd4d4 !important;
  border-radius: 12px !important;
}
.Toastify__toast--error .Toastify__toast-body div {
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal;
  font-weight: 590;
  font-size: 15px;
  line-height: 18px;
  color: #b02a2a;
  letter-spacing: 0.6px;
}
::-webkit-input-placeholder {
  color: #8e8e93 !important;
}
:-ms-input-placeholder {
  color: #8e8e93 !important;
}
::placeholder {
  color: #8e8e93 !important;
}
.action-button {
  border: 2px solid #4fb538 !important;
  border-radius: 15px !important;
  background-color: #fff !important;
  height: 45px !important;
  width: 100%;
  max-width: 172px !important;
  text-transform: capitalize !important;
  font-style: normal;
  font-weight: 590;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.16px;
  color: #43b02a !important;
}
.select-filter {
  border-radius: 15px !important;
  background-color: #fff !important;
  height: 45px !important;
  width: 100%;
  max-width: 255px !important;
  margin-left: 20px;
  text-transform: capitalize !important;
  font-style: normal;
  font-weight: 590;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  letter-spacing: 0.16px;
}

.search-input .MuiInputBase-formControl {
  border-radius: 15px !important;
}
.search-input .MuiOutlinedInput-input {
  background-color: #fff !important;
  height: 44px !important;
  box-sizing: border-box !important;
  padding: 0;
  width: 100%;
  max-width: 255px !important;
  margin-left: 0px;
  font-style: normal;
  font-weight: 590;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.16px;
  padding: 10px 10px 10px 0 !important;
}
.search-input .MuiInputAdornment-root {
  padding: 0px 5px 0 5px;
}
.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.form-switch i {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}
.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}
.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.form-switch input {
  display: none;
}
.form-switch input:checked + i {
  background-color: #4bd763;
}
.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
.dummy-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-record-found-icon {
  max-width: 30px !important;
  max-height: 30px !important;
  margin-bottom: 10px !important;
}

.table-header {
  margin-top: 15px;
  padding: 10px 0 0 0;
  overflow-y: scroll;
}
.table-header .label {
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal;
  font-weight: 590;
  font-size: 13px;
  line-height: 16px;
  color: #8e8e93;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #d1d1d6;
  display: flex;
  align-items: center;
}
.table-header .label.sortable {
  cursor: pointer;
}
.table-header .label img.asc {
  margin-left: 5px;
  height: 8px;
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.table-header .label img.desc {
  margin-left: 5px;
  height: 8px;
  transform: rotate(180deg);
}
.table-header .label:first-child {
  padding-left: 20px;
}
.content-area {
  overflow: hidden;
  overflow-y: scroll;
  height: 540px;
}
.table-content {
  margin: auto;
}
.content-area .table-content:last-child .detail {
  border-bottom: 0 !important;
}
.table-content .detail {
  box-sizing: border-box;
  font-style: normal;
  font-weight: 590;
  font-size: 15px;
  line-height: 24px;
  color: #8e8e93;
  text-transform: capitalize;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #d1d1d6;
}
.table-content .detail:first-child {
  padding-left: 20px;
}
.pager-count-panel {
  padding: 15px 0;
  align-items: baseline;
}
.pager-count-panel .data-count-panel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  letter-spacing: 0.6px;
}
.pager {
  justify-content: end;
  display: flex;
  align-items: center;
  list-style: none;
}

.pager li a {
  background: #ffffff;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: 'SF-Pro-Medium' !important;
}
.pager li .page-item {
  background: #ffffff;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: 'SF-Pro-Medium' !important;
  color: #8e8e93;
  font-size: 15px;
}
.pager li {
  padding-right: 10px;
}
.pager li:first-child a,
.pager li:last-child a {
  background-color: #fff;
  cursor: pointer;
  border: 0;
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal;
  font-weight: 510;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.16px;
  color: #43b02a;
  text-transform: capitalize;
}
.pager li a.active {
  border: 1px solid #43b02a;
  color: #43b02a;
}
.table-content .detail .name {
  color: #43b02a;
  font-family: 'SF-Pro-Medium' !important;
  padding-bottom: 10px;
  font-size: 15px;
  padding-right: 10px;
}
.table-content .detail.role {
  padding-left: 15px;
}
.table-content .detail.role,
.table-content .detail.date {
  font-family: 'SF-Pro-Medium' !important;
  color: #000000 !important;
  padding-right: 40px;
}
.table-content .detail.date {
  font-size: 14px;
}
.table-content .detail .status-label {
  width: 80px;
  height: 32px;
  background: rgba(67, 176, 42, 0.1);
  border-radius: 30px;
  display: inline-flex;
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: 590;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #43b02a;
  align-items: center;
  justify-content: center;
}
#long-menu li {
  font-family: 'SF-Pro-Medium' !important;
  font-size: 14px !important;
}
#long-menu .MuiPopover-paper {
  width: 180px;
  border: 1px solid #d1d1d6;
  box-shadow: none !important;
  box-shadow: 0px 0px 3px rgba(238, 238, 238, 0.5) !important;
  border-radius: 12px;
}
.pager .disabled a {
  color: #8e8e93 !important;
  cursor: default;
}
.pager li.active .page-item {
  border: 1px solid #43b02a !important;
  color: #43b02a !important;
}
.fc-delete-modal .action-box .del-btn,
.bs-delete-modal .action-box .del-btn {
  background: #ffffff;
  border: 2px solid #ff3b30;
  border-radius: 15px;
  width: 200px;
  height: 45px;
  font-family: 'SF-Pro-Bold' !important;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-align: center;
  color: #ff3b30;
  cursor: pointer;
  text-transform: capitalize !important;
}
.fc-delete-modal .action-box,
.bs-delete-modal .action-box {
  justify-content: flex-end;
  display: flex;
  padding: 20px 0 0 0;
}
.fc-delete-modal .action-box .cancel-btn,
.bs-delete-modal .action-box .cancel-btn {
  background: #ffffff;
  border: 0;
  cursor: pointer;
  font-family: 'SF-Pro-Bold' !important;
  font-style: normal;
  font-weight: 510;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  width: 120px;
  text-transform: capitalize !important;
}
.bs-delete-modal .loader-del #loader_container,
.fc-delete-modal .loader-del #loader_container {
  background: #fff !important;
  border: 2px solid #ff3b30;
  width: 215.64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px !important;
  box-sizing: border-box;
}
.fc-delete-modal .loader-del #loader_container {
  width: 200px !important;
}
.bs-delete-modal .loader-del #loader_container .lds-ellipsis,
.fc-delete-modal .loader-del #loader_container .lds-ellipsis {
  height: 45px !important;
}
.bs-delete-modal .loader-del #loader_container .lds-ellipsis div,
.fc-delete-modal .loader-del #loader_container .lds-ellipsis div {
  background: #ff3b30;
}
