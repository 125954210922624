.reset-password-bg {
  background: radial-gradient(100% 197.75% at 0% 0%, #228848 0%, #8edd65 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-form-class {
  width: 75% !important;
  padding-left: 10% !important;
}


.reset-password-form {
  margin-top: 30px;
  width: 610px !important;
  min-height: 480px !important;
  /* overflow: auto; */
  border-radius: 24px !important;
  background-color: white !important;
}


.reset-password-title {
  padding-left: 10% !important;
  font-size: 36px !important;
  font-style: Bold !important;
  font-family: 'SF-Pro-Bold' !important;
  line-height: 40px !important;
  letter-spacing: 0.22px !important;
  margin-top: 65px !important;
  margin-bottom: 30px !important;
  font-weight: 700 !important;
  color: black !important;
}

.reset-label {
  font-family: 'SF-Pro-Bold' !important;
  font-style: Semibold !important;
  font-weight: 590 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #1E272E !important;
  margin-bottom: 15px !important;
}

.reset-button {
  background-color: #D1D1D6 !important;
  font-size: 16px !important;
  font-weight: 590 !important;
  font-stretch: normal;
  font-style: Semibold !important;
  letter-spacing: 1.16px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  width: 100% !important;
  margin-left: 2px !important;
  line-height: 24px !important;
  font-family: 'SF-Pro-Regular' !important;
  margin-top: 10px !important;
  margin-bottom: 60px !important;
}

.reset-button-show {
  background-color: #43B02A !important;
  font-size: 16px !important;
  font-weight: 590 !important;
  font-stretch: Semibold !important;
  font-style: normal;
  letter-spacing: 1.16px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  width: 100% !important;
  margin-left: 2px !important;
  line-height: 24px !important;
  font-family: 'SF-Pro-Regular' !important;
  margin-top: 10px !important;
}

.reset-inputfield {
  font-family: 'SF-Pro-Regular' !important;
  font-size: 16px !important;
  font-weight: 590;
  font-style: normal !important;
  line-height: 24px !important;
  letter-spacing: 0.2px !important;
  color: #1e272e !important;
  margin-top: 0px !important;
  border: none !important;
  margin-bottom: 32px !important;
}

.reset-inputfield::before {
  border-bottom: 2px solid #8E8E93 !important;
}

.reset-inputfield::after {
  border-bottom: 2px solid #8E8E93 !important;
}


.reset-error {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: regular !important;
  line-height: 16px !important;
  letter-spacing: 0.23px !important;
  color: #FF3B30 !important;
  /* margin-top: 5px !important; */
  /* margin-bottom: 10px !important; */
  font-family: 'SF-Pro-Regular' !important;
}

/* .reset-password-form.reset-error
  {
    min-height: 512px !important;
    margin-top: 30px;
    width: 610px !important;
 
    border-radius: 24px !important;
    background-color: white !important;
  } */


/*  Password Updated CSS */

.password-updated-bg {
  background: radial-gradient(100% 197.75% at 0% 0%, #228848 0%, #8edd65 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-updated-form {
  margin-top: 30px;
  width: 610px !important;
  height: 392px !important;
  overflow: auto;
  border-radius: 24px !important;
  background-color: white !important;
}

.password-updated-title {
  padding-left: 10% !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Bold' !important;
  line-height: 23.87px !important;
  letter-spacing: 0.23px !important;
  margin-top: 40px !important;
  margin-bottom: 15px !important;
  font-weight: 590 !important;
}

.password-updated-text {
  padding-left: 10% !important;
  margin-right: 12% !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Regular' !important;
  line-height: 24px !important;
  letter-spacing: 0.23px !important;
  margin-bottom: 45px !important;
  font-weight: 400;
  color: black !important;
}

.Reset-back-to-login {
  background-color: #43B02A !important;
  font-size: 16px !important;
  font-weight: 590 !important;
  font-stretch: normal;
  font-style: bold;
  letter-spacing: 0.56px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: none !important;
  border-radius: 15px !important;
  width: 214px !important;
  line-height: 24px !important;
  font-family: 'SF-Pro-Regular' !important;
  box-shadow: none !important;
  height: 48px !important;
}

.reset-box-button {
  text-align: center !important;
}

.reset-back-to-login-link {
  text-decoration: none !important;
}

.password-updated-done-icon {
  width: 21px !important;
  height: 21px !important;
  color: #43B02A !important;
  z-index: 1 !important;
}

.password-updated-done-icon-bg {
  background-color: rgba(67, 176, 42, 0.2) !important;
  width: 56px !important;
  height: 56px !important;
  border: none !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.password-updated-head-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 10% !important;
}