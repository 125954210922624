.forgot-form {
  margin-top: 30px;
  width: 610px !important;
  min-height: 424px !important;
  overflow: auto;
  border-radius: 24px !important;
  background-color: white !important;
  padding-left: 8% !important;
  padding-right: 10% !important;
}

.forgot-password-title {
  padding-left: 3.5% !important;
  font-size: 20px !important;
  font-style: bold !important;
  font-family: 'SF-Pro-Bold' !important;
  line-height: 100% !important;
  letter-spacing: 0.23px !important;
  margin-top: 70px !important;
  margin-bottom: 30px !important;
  font-weight: 590 !important;
}

.forgot-title-head {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.forgot-back-arrow-icon {
  padding-left: 10% !important;
  margin-top: 45px !important;
  height: 14px !important;
}

.forgot-back-icon-link {
  color: black !important;
  padding-left: 8.5% !important;
}

.forgot-password-text {
  padding-left: 10% !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Regular' !important;
  line-height: 24px !important;
  letter-spacing: 0.23px !important;
  margin-bottom: 35px !important;
  font-weight: 400;
  color: black !important;
}

.forget-password {
  color: #43B02A !important;
  font-size: 16px !important;
  line-height: 1.5;
  letter-spacing: 0.23px !important;
  font-family: 'SF-Pro-Regular' !important;
  cursor: pointer;
  font-style: normal;
  text-decoration: underline;
  margin-top: 25;
  margin-bottom: 30;
  display: inline-block !important;
}

.auth-inputField,
.forgot-inputField {
  margin-bottom: 15px !important;
}

/* Email Verification CSS */

.verification-email-bg {
  background: radial-gradient(100% 197.75% at 0% 0%, #228848 0%, #8edd65 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-verification-form {
  margin-top: 30px;
  width: 610px !important;
  height: 368px !important;
  overflow: auto;
  border-radius: 24px !important;
  background-color: white !important;
}

.email-sent-title {
  padding-left: 10% !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Bold' !important;
  line-height: 23.87px !important;
  letter-spacing: 0.23px !important;
  margin-top: 40px !important;
  margin-bottom: 15px !important;
  font-weight: 590 !important;
}

.email-sent-text {
  padding-left: 10% !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-family: 'SF-Pro-Regular' !important;
  line-height: 24px !important;
  letter-spacing: 0.23px !important;
  margin-bottom: 45px !important;
  font-weight: 400;
  color: black !important;
}

.email-sent-back-to-login {
  background-color: #43B02A !important;
  font-size: 16px !important;
  font-weight: 590 !important;
  font-stretch: normal;
  font-style: Semibold;
  letter-spacing: 0.56px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: none !important;
  border-radius: 15px !important;
  width: 214px !important;
  line-height: 24px !important;
  font-family: 'SF-Pro-Regular' !important;
  box-shadow: none !important;
  height: 48px !important;
}

.email-sent-box-button {
  text-align: center !important;
}

.email-sent-back-to-login-link {
  text-decoration: none !important;
}

.email-sent-done-icon {
  width: 21px !important;
  height: 21px !important;
  color: #43B02A !important;
  z-index: 1 !important;
}

.email-sent-done-icon-bg {
  background-color: rgba(67, 176, 42, 0.2) !important;
  width: 56px !important;
  height: 56px !important;
  border: none !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.email-sent-head-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 10% !important;
}