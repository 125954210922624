#main-wapper {
  margin: 0 auto;
  padding: 0;
  min-width: 1024px;
}
#container {
  padding: 35px 30px 30px 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
#container #sidebar-content {
  max-width: 330px;
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  margin-right: 30px;
  box-sizing: border-box;
  padding: 40px 15px 120px 15px;
  transition: width 0.3s;
}
#container #sidebar-content.collapse {
  width: 105px !important;
}
#container #main-content {
  padding: 20px 0px 20px 30px;
  background: #ffffff;
  width: 70%;
  transition: width 0.5s;
}
#container #main-content.full-width {
  width: 100% !important;
}
#container #sidebar-content .top-head {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: right;
  justify-content: space-between;
}
#container #sidebar-content.collapse .top-head {
  justify-content: center;
}
#container #sidebar-content .top-head .logo {
  padding-left: 20px;
}
#container #sidebar-content.collapse .logo {
  display: none;
}
#container #sidebar-content .top-head .menu-icon {
  float: right;
  background-color: #fff;
  border: 0;
  cursor: pointer;
}

#container #sidebar-content .link-list {
  margin-top: 25px;
}

#container #sidebar-content .link-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#container #sidebar-content .link-list ul li {
  padding: 5px 32px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  min-height: 54px;
  box-sizing: border-box;
}
#container #sidebar-content.collapse .link-list ul li {
  padding: 0;
  justify-content: center;
}
#container #sidebar-content .link-list ul li a {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}
#container #sidebar-content.collapse .link-list ul li a {
  width: auto;
}
#container #sidebar-content .link-list ul li.active {
  background: rgba(67, 176, 42, 0.1);
  border-radius: 12px;
}
#container #sidebar-content .link-list ul li svg {
  width: 25px;
  transition: 0.3s;
}
#container #sidebar-content .link-list ul li:hover svg *,
#container #sidebar-content .link-list ul li:hover a p,
#container #sidebar-content .link-list ul li.active svg *,
#container #sidebar-content .link-list ul li.active p {
  fill: #43b02a;
  color: #43b02a;
}
#container #sidebar-content .link-list ul li svg {
  margin-right: 16px;
}
#container #sidebar-content.collapse .link-list ul li svg {
  margin-right: 0;
}
#container #sidebar-content .link-list ul li p {
  font-family: 'SF-Pro-Medium' !important;
  font-size: 14px;
  color: #8e8e93;
  transition: 0.5s;
}
#container #sidebar-content.collapse .link-list ul li p {
  display: none;
}

#container #main-content .content-panel h1 {
  font-family: 'SF-Pro-Medium' !important;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 25px;
  color: #000000;
  padding: 0;
  margin: 0;
}
#container #main-content .content-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#container #main-content .content-panel .user-panel {
  float: right;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
#container #main-content .content-panel .user-panel .name {
  padding-right: 30px;
  font-family: 'SF-Pro-Medium' !important;
  cursor: pointer;
}
#container #main-content .content-panel .user-panel button {
  color: #43b02a;
  font-family: 'SF-Pro-Bold' !important;
  text-decoration: none;
  border: 0;
  background-color: #fff;
  cursor: pointer;
}
#container #main-content hr {
  border: 0;
  border-top: 1px solid #d1d1d6;
  margin: 15px 0 10px 0;
}
